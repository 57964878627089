@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

/* Variables */
:root {
  --gradient: linear-gradient(265.6deg, #007fff -21.43%, #0059b2 100%);
  --primary-blue: #007fff;
  --secondary-blue: #0059b2;
  --dark-text: #001e3c;
  --light-bg: #fafbfc;
  --technologies-bg: rgba(102, 178, 255, 0.3);
  --project-border: #aab4be;
  --form-input-bg-color: #eaeef3;
  --form-input-border-color: #6f6f6f;
  --form-error-message-color: #d22222;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
  background: var(--light-bg);
  /* padding: 5%; */
  /* margin: 5%; */
}

/* General styles */

.App {
  width: 100%;
  /* max-width: 955px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.horizontal-line,
.skill-separator {
  min-width: 100%;
  border: none;
  background-color: var(--project-border);
  height: 1px;
}

a:focus-visible,
button:focus-visible {
  border-radius: 8px;
  outline: 3px solid var(--primary-blue);
}

/* Scrollbar */
::-webkit-scrollbar {
  background-color: hsl(210, 13%, 81%);
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: hsl(210, 25%, 99%);
  width: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: #e0dbe0;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(210, 13%, 71%);
}

/* NAVIGATION */
.nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  height: 60px;
  z-index: 9;
  background-color: rgba(250, 251, 252, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 955px;
  width: 100%;
  padding: 0 3%;
}

.nav-horizontal-line {
  position: fixed;
  top: 60px;
  z-index: 9;
}

.left-menu {
  display: flex;
  gap: 5px;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  color: var(--dark-text);
  border-radius: 8px;
}

/* .home a {
} */

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid var(--project-border);
  border-radius: 8px;
  color: var(--dark-text);
  cursor: pointer;
  background: transparent;
}

.menu:focus-visible {
  outline: 3px solid var(--primary-blue);
}

.home:focus-visible {
  outline: 3px solid var(--primary-blue);
}

.menu:hover,
.home:hover {
  background-color: var(--technologies-bg);
}

.menu-open {
  width: 100%;
  min-height: 100vh;
  background-color: var(--light-bg);
  position: absolute;
  top: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 65px 0;
}

.menu-open a {
  text-decoration: none;
  color: var(--dark-text);
  font-weight: 700;
  font-size: 1.8rem;
  padding: 22px 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-open .active-nav::after {
  display: none;
}

.menu-closed {
  display: none;
}

.main-nav {
  display: none;
}

.main-nav a {
  text-decoration: none;
  color: var(--dark-text);
  font-weight: 700;
  font-size: 1.8rem;
  padding: 20px 10px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.main-nav a:focus-visible {
  border-radius: 8px;
  outline: 3px solid var(--primary-blue);
  outline-offset: -3px;
}

.main-nav a:hover {
  color: var(--secondary-blue) !important;
  font-style: italic;
}

.active-nav {
  color: var(--secondary-blue) !important;
  font-style: italic;
}

.main-nav {
  position: relative;
}

.active-nav::after {
  content: "";
  height: 5px;
  width: 35px;
  background-color: var(--secondary-blue);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: absolute;
  bottom: 0;
}

.main-nav a:hover::after {
  content: "";
  height: 5px;
  width: 35px;
  background-color: var(--secondary-blue);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: absolute;
  bottom: 0;
}

.secondary-nav {
  display: flex;
  align-items: center;
  gap: 14px;
}

.secondary-nav a {
  text-decoration: none;
  color: var(--dark-text);
}

.contact-options {
  display: flex;
  gap: 4px;
}

.contact-options a {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  /* border: 1px solid transparent; */
}

.contact-options a:focus-visible {
  /* border: 1px solid var(--dark-text); */
  outline: 0;
  outline: 3px solid var(--primary-blue);
}

.contact-options a:hover {
  background-color: var(--technologies-bg);
}

.darkmode-toggle {
  color: var(--dark-text);
  display: flex;
  align-items: center;
  padding: 7px;
  border: 1px solid var(--project-border);
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
}

.darkmode-toggle:focus-visible {
  border: 1px solid var(--dark-text);
  outline: 0;
  outline: 3px solid var(--primary-blue);
}

.darkmode-toggle:hover {
  border: 1px solid var(--secondary-blue);
  background-color: var(--technologies-bg);
}

/* HERO */

.hero {
  padding: 0 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  height: 70vh;
  max-width: 955px;
  margin-top: 60px;
}

.headline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-title {
  line-height: 100%;
  font-size: 4rem;
  font-weight: 900;
  color: var(--dark-text);
  max-width: 350px;
}

.gradient {
  color: var(--gradient);
  background: var(--gradient);
  font-style: italic;
  line-height: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  color: var(--dark-text);
  font-size: 2.4rem;
  line-height: 150%;
  max-width: 300px;
}

.hero-subtitle-italic {
  font-style: italic;
  font-weight: 700;
}

.hero-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.section-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

button {
  border: 0;
}

.primary-btn {
  background: var(--gradient);
  border-radius: 8px;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 125%;
  color: white;
  padding: 10px 25px;
  text-decoration: none;
}

.primary-btn a {
  color: #ffffff;
  text-decoration: none;
}

.secondary-btn {
  background: none;
  border: 2px solid var(--secondary-blue);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 125%;
  color: var(--secondary-blue);
  padding: 10px 25px;
  text-decoration: none;
}

.project-btns {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.secondary-btns {
  display: flex;
  gap: 15px;
}

.live-btn,
.code-btn {
  color: var(--dark-text);
  background: transparent;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 6px;
}

.live-btn:hover,
.code-btn:hover {
  color: var(--secondary-blue);
  background-color: var(--technologies-bg);
}

.live-btn:focus-visible,
.code-btn:focus-visible {
  outline: 3px solid var(--primary-blue);
}

.live-btn:active,
.code-btn:active {
  transform: scale(96%);
  transform-origin: center;
}

.primary-btn:hover {
  cursor: pointer;
  background: var(--secondary-blue);
}

.secondary-btn:hover {
  cursor: pointer;
  color: var(--secondary-blue);
  background-color: var(--technologies-bg);
}

.primary-btn:focus-visible {
  outline: 3px solid var(--primary-blue);
}

.secondary-btn:focus-visible {
  outline: 3px solid var(--primary-blue);
}

.primary-btn,
.secondary-btn {
  transform-origin: center;
}
.primary-btn:active,
.secondary-btn:active {
  transform: scale(97%);
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

/* .disabled-link:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
} */

/* .disabled-link a {
  pointer-events: none;
}

.project-link a{
  cursor: not-allowed !important;
}
.project-link{
  cursor: not-allowed !important;
} */

/* PROJECTS */
.projects {
  color: var(--dark-text);
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 70px 3% 50px 3%;
  /* margin-top: 60px; */
  max-width: 955px;
}

.projects-header {
  max-width: 300px;
}

.project-description {
  line-height: 180%;
}

.projects-header-title,
.about-header-title,
.contact-header-title {
  font-size: 3rem;
  font-weight: 700;
  padding-bottom: 12px;
}

.projects-header-description,
.contact-header-description {
  line-height: 180%;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.project {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--project-border);
  border-radius: 8px;
  padding: 20px;
  gap: 20px;
  background-color: white;
  max-width: 350px;
}

.project:hover {
  border: 1px solid var(--secondary-blue);
}

.project:hover .project-title .title {
  color: var(--secondary-blue);
}

.project-type {
  color: hsl(210, 14%, 71%);
  display: flex;
  align-items: center;
}

.project-picture {
  width: 100%;
  height: 20rem;
  background-color: var(--dark-text);
  border-radius: 8px;
  border: 1px solid var(--project-border);
}

.about-picture {
  /* min-width: 435px; */
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
  border-radius: 8px;
}

.project-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 2.4rem;
  font-weight: 700;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.technology {
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0.4rem 0.8rem;
  background-color: var(--technologies-bg);
  border-radius: 22px;
}

.project-details {
  display: flex;
  flex-direction: column;
  padding: 100px 3% 50px 3%;
  width: 100%;
  max-width: 955px;
  color: var(--dark-text);
  gap: 40px;
}

.project-details p {
  line-height: 180%;
}

.project-details-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.project-details-heading {
  font-size: 3.6rem;
  line-height: 100%;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.project-details-picture {
  width: 100%;
  /* height: 20rem; */
  background-color: var(--dark-text);
  border-radius: 8px;
  border: 1px solid var(--project-border);
}

.projects-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  /* color: var(--project-border); */
  color: var(--dark-text);
  font-size: 1.8rem;
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;
  padding-bottom: 4px;
  align-self: flex-start;
  padding: 8px 8px 8px 0;
  /* border-radius: 8px; */
  /* transform: translateX(-10px); */
  /* border-bottom: 1px solid var(--project-border); */
}

.projects-back-btn:hover {
  color: var(--primary-blue);
  /* background-color: var(--technologies-bg); */
  /* border-bottom: 1px solid var(--primary-blue); */
}

.projects-back-btn-icon {
  display: flex;
  padding-right: 5px;
}

.project-links {
  display: flex;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
}
.project-link {
  display: flex;
  flex-direction: column;
}

.link-border {
  border: none;
  background-color: var(--project-border);
  height: 1px;
}

.project-link:hover .link-border {
  background-color: var(--primary-blue);
}

.live-site,
.source-code {
  color: var(--dark-text);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: 4px 0;
}

.live-icon,
.code-icon {
  display: flex;
  color: var(--primary-blue);
  padding-left: 5px;
}

.project-detail-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 30px;
  gap: 10px;
}

.project-detail-details h2 {
  font-size: 2.4rem;
  line-height: 100%;
  min-width: 280px;
}

.project-detail-details div ul {
  padding-left: 20px;
}

.project-detail-details div ul li {
  line-height: 180%;
}

.project-detail a {
  display: inline-block;
  color: var(--secondary-blue);
  text-decoration: none;
  line-height: 150%;
  border-bottom: 1px solid var(--project-border);
}

.project-detail a:hover {
  color: var(--dark-text);
  border-bottom: 1px solid var(--primary-blue);
}

.details-border {
  border: none;
  background-color: var(--project-border);
  height: 1px;
}

.projects p a {
  text-decoration: none;
  color: var(--secondary-blue);
  border-bottom: 1px solid var(--project-border);
}

.projects p a:hover {
  color: var(--dark-text);
  border-bottom: 1px solid var(--primary-blue);
}

/* SKILLS */
.skills {
  color: var(--dark-text);
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 60px 3% 50px 3%;
  max-width: 350px;
}

.skills-header-title {
  font-size: 3rem;
  padding-bottom: 12px;
}

.skills-header-description {
  /* font-size: 1.8rem; */
  line-height: 180%;
}

.skills-technologies {
  display: flex;
  flex-direction: column;
  gap: 43px;
  flex-wrap: wrap;
}

.skill-list-heading {
  font-size: 1.8rem;
  color: #ffffff;
  background-color: var(--dark-text);
  padding: 15px 30px;
  border: 1px solid var(--project-border);
  border-bottom: 0;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
}

.skill-list-wrapper {
  display: flex;
  flex-direction: column;
}

.skills-list {
  border: 1px solid var(--project-border);
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.skill-name {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 14px;
}

.skill-icon {
  display: flex;
  align-items: center;
}

.skill-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-radius: 8px;
  padding: 15px 30px;
  background-color: #ffffff;
  max-height: 70px;
}

/* ABOUT */

.about {
  color: var(--dark-text);
  display: flex;
  flex-direction: column;
  max-width: 955px;
  padding: 60px 3% 50px 3%;
  gap: 20px;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 350px;
}

.about-text {
  line-height: 180%;
  padding-bottom: 25px;
}

.about p a {
  text-decoration: none;
  color: var(--secondary-blue);
  border-bottom: 1px solid var(--project-border);
}

.about p a:hover {
  color: var(--dark-text);
  border-bottom: 1px solid var(--primary-blue);
}

/* CONTACT */

.contact {
  color: var(--dark-text);
  display: flex;
  flex-direction: column;
  max-width: 955px;
  padding: 60px 3% 50px 3%;
  gap: 35px;
  max-width: 350px;
}

#contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact-header-description {
  /* font-size: 1.8rem; */
  line-height: 180%;
}

.contact-form-email {
  display: flex;
  font-weight: 700;
}

.contact-form-email-icon {
  color: var(--dark-text);
  display: flex;
  padding-right: 8px;
}

.contact-form-field {
  display: flex;
  flex-direction: column;
}

.contact-form-field > input,
.contact-form-field > textarea {
  color: var(--dark-text);
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
}

.contact-form-field > input {
  /* display: flex;
  flex-direction: column; */
  min-height: 40px;
  background-color: var(--form-input-bg-color);
  border: 1px solid var(--form-input-border-color);
  border-radius: 8px;
}

.contact-form-field > textarea {
  /* display: flex;
  flex-direction: column; */
  min-height: 130px;
  background-color: var(--form-input-bg-color);
  border: 1px solid var(--form-input-border-color);
  border-radius: 8px;
}

/* .contact-form-field > input[type="text"]:focus-visible,
.contact-form-field > input[type="email"]:focus-visible,
.contact-form-field > textarea:focus-visible {
} */

.contact-form-field > input:focus,
.contact-form-field > textarea:focus {
  border-color: var(--secondary-blue);
  outline: 0;
  outline: 3px solid var(--primary-blue);
  background-color: #ffffff;
}

.contact-form-field > input:hover,
.contact-form-field > textarea:hover {
  border-color: var(--secondary-blue);
}

.contact-form-field label {
  font-weight: 700;
  padding-bottom: 10px;
}

#contact-form button {
  align-self: flex-end;
}

.send-message {
  border: 1px solid transparent;
}
.send-message:focus-visible {
  border: 1px solid white;
  outline: 3px solid var(--primary-blue);
}

.footer {
  padding: 70px 0;
  max-width: 955px;
  color: var(--dark-text);
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.contact-icon {
  color: var(--dark-text);
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.contact-icon:hover {
  background-color: var(--technologies-bg);
}

.not-found-page {
  display: flex;
  flex-direction: column;
  color: var(--dark-text);
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 25px;
  padding: 3%;
}

.not-found-page h1 {
  line-height: 100%;
  font-size: 7.2rem;
}

.not-found-page div {
  line-height: 150%;
  font-size: 2.4rem;
}

@media screen and (min-width: 600px) {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero {
    align-items: center;
    text-align: center;
  }
  .hero-title,
  .gradient {
    max-width: 100%;
    font-size: 4.8rem;
  }

  .hero-subtitle {
    max-width: 584px;
  }

  .projects,
  .about,
  .skill {
    align-items: center;
  }
  .project {
    max-width: 100%;
  }
  .projects-header {
    text-align: center;
    max-width: 400px;
  }
  .project-details-heading {
    align-items: center;
  }
  .project-type {
    justify-self: flex-start;
  }

  .skills-technologies {
    max-width: 100%;
  }

  .projects,
  .about,
  .skills {
    max-width: 483px;
  }

  .about-content {
    max-width: 483px;
  }

  .contact {
    max-width: 483px;
  }

  .contact-header,
  .skills-header {
    text-align: center;
  }
}

@media screen and (min-width: 900px) {
  .nav-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    height: 60px;
    background-color: rgba(250, 251, 252, 0.7);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 955px;
    width: 100%;
    padding: 0;
  }

  .nav-horizontal-line {
    position: fixed;
    top: 60px;
  }

  .left-menu {
    display: none;
  }

  .menu-open {
    display: none;
  }

  .main-nav {
    visibility: visible;
    display: flex;
    gap: 31px;
  }

  .main-nav {
    position: relative;
  }

  .secondary-nav {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  /* .secondary-nav a {
    text-decoration: none;
    color: var(--dark-text);
  } */

  .contact-options {
    display: flex;
    gap: 18px;
  }

  .hero {
    margin-top: 0;
    height: 100vh;
    gap: 25px;
  }

  .hero-title {
    font-size: 6.4rem;
    color: var(--dark-text);
    line-height: 100%;
  }

  .hero-subtitle {
    color: var(--dark-text);
    font-size: 2.4rem;
    line-height: 125%;
    max-width: 100%;
  }

  .hero-buttons {
    display: flex;
    gap: 20px;
  }

  button {
    border: 0;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .project {
    display: flex;
    flex-direction: row;
  }

  .projects {
    align-items: center;
    gap: 50px;
    padding: 70px 0;
  }

  .projects-header {
    text-align: center;
    max-width: 428px;
  }
  .projects-header-title {
    font-size: 3.6rem;
  }
  .projects-header-description {
    line-height: 180%;
  }

  .project {
    gap: 27px;
    padding: 30px;
  }

  .project-list {
    gap: 50px;
  }

  .project-picture {
    min-width: 435px;
    height: 100%;
  }

  .project-details-wrapper {
    min-width: 435px;
  }

  .project-details {
    padding-left: 0;
    padding-right: 0;
  }

  .project-details-heading {
    font-size: 3.6rem;
  }

  .project-detail-details {
    flex-direction: row;
    gap: 0;
  }
  .project-detail-details > h2 {
    font-size: 2.4rem;
    max-width: 250px;
  }

  .project-detail-details > div {
    align-self: flex-start;
  }

  .skills {
    max-width: 955px;
    align-items: center;
    gap: 50px;
    padding: 70px 0;
  }

  .skills-header-title,
  .skills-header-description {
    text-align: center;
    max-width: 483px;
  }

  .skills-header-title {
    font-size: 3.6rem;
  }

  .skills-technologies {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .skill {
    min-width: 453px;
  }

  .about {
    align-items: center;
    gap: 50px;
    max-width: 955px;
    padding: 70px 0;
  }

  .about-header-title {
    font-size: 3.6rem;
  }

  .about-content {
    flex-direction: row;
    gap: 53px;
    max-width: 100%;
  }

  .about-picture {
    /* max-width: 478px; */
    /* min-width: 435px;
    height: 280px; */
    /* object-fit: contain; */
    /* object-position: top; */
    max-height: 200px;
  }

  .about-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .about-text {
    max-width: 100%;
    line-height: 180%;
  }

  .contact {
    max-width: 483px;
    padding: 70px 0;
    gap: 50px;
  }

  .contact-header-title {
    font-size: 3.6rem;
  }

  /* PAGE NOT FOUND */
  .not-found-page h1 {
    font-size: 9.6rem;
  }

  .not-found-page div {
    font-size: 3.6rem;
  }
}

.skip-section-btn-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 955px;
  margin-top: 0.5em;
  opacity: 0;
  pointer-events: none;
}
.skip-section-btn-wrapper:focus-within {
  opacity: 1;
  pointer-events: visible;
}
.skip-section-btn {
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: var(--light-bg);
}
.skip-section-btn:hover {
  background-color: var(--light-bg);
}

@media screen and (max-width: 900px) {
  .nav-wrapper {
    position: fixed;
  }
  .skip-section-btn-wrapper {
    margin-left: 6%;
  }
}
@media screen and (max-width: 600px) {
  .skip-section-btn-wrapper {
    margin-top: 0;
  }
  .skip-section-btn-wrapper {
    margin-left: 0%;
  }
}

.form-error-message {
  font-size: 1.5rem;
  margin-top: 4px;
  color: var(--form-error-message-color);
}
